import React, { Component } from "react";
import "./Header.css";
import * as helpers from "../helpers/helpers";
import mainConfig from "../config.json";
import { Menu, ExitToApp, LockOpen } from '@material-ui/icons';
import Button from "@material-ui/core/Button";

import LoginDialog from '../login/loginDialog'

//Profile User
import ProfileUserDialog from '../user/ProfileUserDialog';

const feedbackTemplate = (url, xmin, xmax, ymin, ymax, centerx, centery, scale) => `${url}/?xmin=${xmin}&xmax=${xmax}&ymin=${ymin}&ymax=${ymax}&centerx=${centerx}&centery=${centery}&scale=${scale}`;
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenSettingsAccount: false,
      isOpenLoginDialog: false
    };

    // LISTEN FOR FEEDBACK
    window.emitter.addListener("feedback", () => this.onFeedbackClick());
  }

  componentDidMount() {
    window.emitter.emit("headerLoaded");
  }

  componentDidUpdate() {
    window.emitter.emit("setSidebarVisiblity", "OPEN");
    helpers.addAppStat("Burger Button", "Click");
  }

  burgerButtonHandler() {
    // EMIT A CHANGE IN THE SIDEBAR (IN OR OUT)
    if (window.sidebarOpen) window.emitter.emit("setSidebarVisiblity", "CLOSE");
    else window.emitter.emit("setSidebarVisiblity", "OPEN");

    helpers.addAppStat("Burger Button", "Click");
  }

  openLoginDialog = () => {
    this.setState(prevState => ({
      isOpenLoginDialog: !prevState.isOpenLoginDialog
    }))
  }

  refreshPage = () => {
    localStorage.clear()
    window.location.reload();
  }

  openProfileAccount = () => {
    this.setState(prevState => ({
      isOpenProfileAccount: !prevState.isOpenProfileAccount
    }))
  }


  onMenuItemClick = (value) => {
    helpers.showMessage("LogOut", "Logout in corso...");
    helpers.addAppStat("Header Dot Menu", value);
  };

  onFeedbackClick = () => {
    // APP STATS
    helpers.addAppStat("Feedback", "Click (Header)");

    const scale = helpers.getMapScale();
    const extent = window.map.getView().calculateExtent(window.map.getSize());
    const xmin = extent[0];
    const xmax = extent[1];
    const ymin = extent[2];
    const ymax = extent[3];
    const center = window.map.getView().getCenter();

    const feedbackUrl = feedbackTemplate(mainConfig.feedbackUrl, xmin, xmax, ymin, ymax, center[0], center[1], scale);

    helpers.showURLWindow(feedbackUrl, false, "full");
  };

  render() {
    const { isOpenProfileAccount, isOpenLoginDialog } = this.state
    const userType = localStorage.getItem("USER_TYPE")


    return (
      <div className="header" >
        <div
          id="sc-header-burger-button"
          onClick={this.burgerButtonHandler}
          tabIndex="2"
          onKeyPress={(evt) => {
            if (evt.key === "Enter") {
              this.burgerButtonHandler();
            }
          }}
        >
          <Menu style={{ color: 'white' }} />
        </div>

        <div id="sc-header-bar-button">
          <Menu style={{ color: 'white' }} />
        </div>

        <div className="sc-header-logo">

          <b variant="outline" startIcon={<LockOpen />}
            style={{ marginRight: '25px', color: '#FFFFFF' }}
            edge="start" color="inherit" > Comune di Enna</b>
          <img src={require("./images/logo_header.png")} alt="Logo App" height="80%" />
          <div>
            <b variant="outline" startIcon={<LockOpen />}
              style={{ marginLeft: '25px', color: '#FFFFFF' }}
              edge="start" color="inherit"> Portale WebGis</b>
          </div>
        </div>


        <div className="sc-header-dot-menu-container"  >
          {userType === "GUEST" ?

            <Button variant="outline" startIcon={<LockOpen />}
              style={{ marginLeft: '21px', color: '#FFFFFF' }}
              onClick={() => this.openLoginDialog()}
              edge="start" color="inherit" aria-label="downloas">
              AREA RISERVATA
            </Button>

            :

            <Button variant="outline" startIcon={<ExitToApp />}
              style={{ marginLeft: '21px', color: '#FFFFFF' }}
              onClick={() => this.refreshPage()}
              edge="start" color="inherit" aria-label="downloas">
              ESCI
            </Button>
          }
        </div>

        <ProfileUserDialog isOpen={isOpenProfileAccount} closeDialog={this.openProfileAccount} />

        {isOpenLoginDialog &&
          <LoginDialog
            isOpen={isOpenLoginDialog}
            closeDialog={this.openLoginDialog}
          />}

      </div>
    );
  }
}
//
export default Header;

// IMPORT ALL IMAGES
function importAllImages(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace("./", "")] = r(item)));
  return images;
}
