/**
 * Contiene tutte le rotte alle API del BackEnd PiGreco WebGis
 */
export const HostName = 'http://apigis.comune.enna.it:8080';


//API COMBO BOX GLOBALI
export const getAnaLitoOptions = HostName + '/api/combo-box/ana-lito/options';

/**
 * API AUTENTICAZIONE E UTENTI
 */
export const authLogin = HostName + '/api/auth/login';
export const getUserNamePublic = HostName + "/api/utenti/utente/public";
export const GetUserInfo = HostName + '/api/utenti/utente';
export const RecoveryPassword = HostName + '/api/utenti/utente/cambia-password';
export const UpdateUserData = HostName + '/api/utenti/utente';

/**
 * API ENTITA' TERRITORIALI
 */
export const GetCityFromLatLng = HostName + '/api/enti-territoriali/comune/coordinate';
export const GetCityFromInstatCode = HostName + '/api/enti-territoriali/comune';
export const GetProvinces = HostName + "/api/enti-territoriali/province"
export const GetProvinceFromCode = HostName + "/api/enti-territoriali/provincia"
export const GetCityFromProvince = HostName + "/api/enti-territoriali/province/comuni"




/**
 * API SITI PUNTUALI
 */
export const PostPunctualSite = HostName + "/api/siti-puntuali"
export const GetPunctualSite = HostName + "/api/siti-puntuali/sito-puntuale"
export const PutPunctualSite = HostName + "/api/siti-puntuali/sito-puntuale"
export const DeletePunctualSite = HostName + "/api/siti-puntuali/sito-puntuale"

// API INDAGINI SITI PUNTUALI
export const GetPunctualSiteInvestigations = HostName + "/api/siti-puntuali/indagini"
export const PostPunctualSiteInvestigation = HostName + "/api/siti-puntuali/indagini"
export const PostPunctualSiteInvestigationDocument = HostName + "/api/siti-puntuali/indagini/indagine/upload-documento"
export const GetPunctualSiteInvestigation = HostName + "/api/siti-puntuali/indagini/indagine"
export const GetPunctualSIteInvestigationDocument = HostName + "/api/siti-puntuali/indagini/indagine/download-documento"
export const PutPunctualSiteInvestigation = HostName + "/api/siti-puntuali/indagini/indagine"
export const DeletePunctualSiteInvestigation = HostName + "/api/siti-puntuali/indagini/indagine"
export const DeletePunctualSiteInvestigationDocument = HostName + "/api/siti-puntuali/indagini/indagine/documento";

// API PARAMETRI INDAGINE SITO PUNTUALE
export const GetPunctualSiteInvestigationParameters = HostName + "/api/siti-puntuali/indagini/parametri"
export const GetPunctualSiteInvestigationParameter = HostName + "/api/siti-puntuali/indagini/parametri/parametro"
export const PostPunctualSiteInvestigationParameter = HostName + "/api/siti-puntuali/indagini/parametri"
export const PutPunctualSiteInvestigationParameter = HostName + "/api/siti-puntuali/indagini/parametri/parametro"
export const GetInfoTypeParameter = HostName + "/api/combo-box/parametro-puntuale/campi-predefiniti"
export const DeletePunctualSiteInvestigationParameter = HostName + "/api/siti-puntuali/indagini/parametri/parametro"

//API COMBO BOX SITO PUNTUALE
export const GetCoordinateIdentification = HostName + "/api/combo-box/sito-puntuale/identificazione-coordinate"
export const GetQuoteIdentification = HostName + "/api/combo-box/sito-puntuale/identificazione-quota"
export const GetInvestigationClassComboBox = HostName + "/api/combo-box/indagine-puntuale/classe-indagine"
export const GetInvestigationTypeComboBox = HostName + "/api/combo-box/indagine-puntuale/tipo-indagine"
export const GetParameterTypeComboBox = HostName + "/api/combo-box/parametro-puntuale/tipo-parametro"
export const GetParameterMeasurementComboBox = HostName + "/api/combo-box/parametro-puntuale/attendibilita-parametro"

/**
* API SITI LINEARI
 */
export const DeleteLinearSite = HostName + "/api/siti-lineari/sito-lineare"
export const PostLinearSite = HostName + "/api/siti-lineari"
export const PutLinearSite = HostName + "/api/siti-lineari/sito-lineare"
export const GetLinearSite = HostName + "/api/siti-lineari/sito-lineare"

//API INDAGINI SITO LINEARE
export const GetLinearSiteInvestigations = HostName + "/api/siti-lineari/indagini"
export const GetLinearSiteInvestigation = HostName + "/api/siti-lineari/indagini/indagine"
export const PostLinearSiteInvestigation = HostName + "/api/siti-lineari/indagini"
export const PutLinearSiteInvestigation = HostName + "/api/siti-lineari/indagini/indagine"
export const GetLinearSiteInvestigationDocument = HostName + "/api/siti-lineari/indagini/indagine/download-documento"
export const PostLinearSiteInvestigationDocument = HostName + "/api/siti-lineari/indagini/indagine/upload-documento"
export const DeleteLinearSiteInvestigation = HostName + "/api/siti-lineari/indagini/indagine"

//API PARAMETRI INDAGINE SITO LINEARE
export const GetLinearSiteInvestigationParameters = HostName + "/api/siti-lineari/indagini/parametri"
export const GetLinearSiteInvestigationParameter = HostName + "/api/siti-lineari/indagini/parametri/parametro"
export const PostLinearSiteInvestigationParameter = HostName + "/api/siti-lineari/indagini/parametri"
export const PutLinearSiteInvestigationParameter = HostName + "/api/siti-lineari/indagini/parametri/parametro"
export const DeleteLinearSiteInvestigationParameter = HostName + "/api/siti-lineari/indagini/parametri/parametro"
export const DeleteLinearSiteInvestigationDocument = HostName + "/api/siti-lineari/indagini/indagine/documento";


//API COMBO BOX SITO LINEARE
export const GetTracedIdentifications = HostName + "/api/combo-box/sito-lineare/identificazione-tracciato"
export const GetInvestigationLinearSiteClass = HostName + "/api/combo-box/indagine-lineare/classe-indagine"
export const GetInvestigationLinearSiteType = HostName + "/api/combo-box/indagine-lineare/tipo-indagine"
export const GetParameterLinearSiteType = HostName + "/api/combo-box/parametro-lineare/tipo-parametro"
export const GetInfoTypeParameterLinearSite = HostName + "/api/combo-box/parametro-lineare/campi-predefiniti"
export const GetParameterLinearSiteMeasurement = HostName + "/api/combo-box/parametro-lineare/attendibilita-parametro"

// API Allegati
export const GetAttachmentList = HostName + "/api/allegati"
export const GetAttachment = HostName + "/api/allegati/allegato"
export const DownloadAttachmentRoot = HostName + "/api/allegati/allegato/download"
export const GetLegendLayer = HostName + "/api/allegati/allegato/guida-utente"


/**
 * API NODO FRANA
 */
export const ComboBoxCode = {
  cl_pai_pericolo_frana: 1008,
  cl_pai_pericolo_idro: 1009,
  
  cl_pai_rischio: 90110400,

  cl_esp_viabilita: 90300227,
  cl_esp_edificato: 90300229,
  cl_esp_crs: 90300231,
  cl_esp_altro: 90300233,

  cl_dis_dim: 90300224,
  cl_dis_tipo: 90300225,
  cl_dis_opere_frane: 90300226,
    cl_dis_opere_idro: 90300324,

}

export const CodeStatus = {
  annulla: 1004,
  valida: 1005,
}

// API FRANE IDRO
export const GetComboBoxChild = HostName + "/api/combo-box/child"
export const PostNodoFrana = HostName + "/api/frane"
export const GetNodoFrana = HostName + "/api/frane/frana"
export const PutNodoFrana = HostName + "/api/frane/frana"
export const DeleteNodoFrana = HostName + "/api/frane/frana"
export const GetCalcoloPr = HostName + "/api/combo-box/calcolo-pr"
export const GetBacinoFromLatLng = HostName + '/api/enti-territoriali/bacino/coordinate';
export const GetQuadroUnione = HostName + "/api/enti-territoriali/quadro-unione/coordinate"
export const PostAttachment = HostName + "/api/allegati/allegato/feature/upload"
export const PostNodoIdro = HostName + "/api/idro"
export const PutNodoIdro = HostName + "/api/idro/idro"
export const GetNodoIdro = HostName + "/api/idro/idro"
export const GetDighe = HostName + '/api/enti-territoriali/dighe';
export const GetComboEnum = HostName + '/api/combo-box/enum';
export const PutUpdateFranaStatus = HostName + '/api/frane/frana/update-status';
export const PutUpdateIdroStatus = HostName + '/api/idro/idro/update-status';
export const PutReopenNodoIdro = HostName + '/api/idro/idro/reopen';
export const PutReopenNodoFrana = HostName + '/api/frane/frana/reopen';

// API FRANE IDRO REPORT
export const ReportIdroDownload = HostName + '/api/idro/idro/report/download';
export const ReportFranaDownload = HostName + '/api/frane/frana/report/download';
