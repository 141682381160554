import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField, CircularProgress } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Logo from '../../images/logo.png'
import { makeStyles } from '@material-ui/core/styles';

// API REQUEST
import axios from 'axios';
import {
  authLogin
} from '../../../backEnd/requestAPI';

import packageJson from '../../../../package.json';
import moment from 'moment';
import preval from 'preval.macro';
const buildTimestamp = preval`module.exports = new Date().getTime();`;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: "url(/img/image_login.png)",
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


function getDateString() {
  const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
  const formattedDate = lastUpdateMoment.format('DD.MM.YYYY HH:mm:ss');

  return formattedDate;
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="#">
      <p><b>Versione {packageJson.version} del {getDateString()}  </b> </p>
      </Link>
    </Typography>
  );
}

function SignInSide(props) {
  const classes = useStyles();

  const { isErrorAuthorized, checkDataSignIn, isLoadingLogin } = props

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} >
        <div className={classes.paper}>
          <img src={Logo}></img>
          <Typography component="h1" variant="h5">
            Portale WebGis - Enna
          </Typography>

          <Typography style={{ marginTop: '55px' }} component="h1" variant="h5">
            Accedi
          </Typography>
          <form onSubmit={checkDataSignIn} className={classes.form} >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
              error={isErrorAuthorized}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={isErrorAuthorized}
            />

            {isLoadingLogin ?
              <CircularProgress style={{ textAlign: "center" }} />
              :
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                ACCEDI
            </Button>
            }
            {isErrorAuthorized &&
              <Typography style={{ marginBottom: "21px", color: "red", textAlign: "center" }}>
                Username e/o password errati!
            </Typography>
            }
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

class SessionExpiredModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenWebGis: false,
      isOpenMessage: false,
      textMessage: '',
      isErrorAuthorized: false,
      isLoadingLogin: false,
      closeDialog: props.handleClose
    };
  };

  onOpenWebGis = () => {
    this.setState({
      isOpenWebGis: true
    })
  }

  //Apre eventuali messaggi di errore
  openMessage = text => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
    }))
  }

  checkDataSignIn = event => {
    event.preventDefault();

    const { closeDialog } = this.state

    this.setState({
      isLoadingLogin: true,
      isErrorAuthorized: false
    })

    let user = {
      username: event.target.username.value,
      password: event.target.password.value
    }

    axios.post(authLogin,
      user).then(response => {
        let userAuth = response.data
        let userRole = userAuth.ruoli

        this.setState({
          isErrorAuthorized: false,
          isLoadingLogin: false
        }, () => {
          localStorage.setItem("USER_TOKEN", userAuth.access_token);
          localStorage.setItem("USER_ID", userAuth.utente.idUtente);
          localStorage.setItem("USER_NAME", userAuth.utente.nome + " " + userAuth.utente.cognome)
          localStorage.setItem("ROLES_USER", JSON.stringify(userRole));
          closeDialog()
        })


      }).catch(error => {
        this.setState({
          isLoadingLogin: false,
          isErrorAuthorized: true
        })

        let statusCode = error.response.data.statusCode
        if (statusCode == 401) this.openMessage("Utente non autorizzato")
        else alert(error);

      });
  }

  refreshPage = () => {
    localStorage.clear()
    window.location.reload();
  }

  render() {
    const { isErrorAuthorized, isLoadingLogin } = this.state
    const { handleClose, open, confirmFunction } = this.props

    return (

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Sessione scaduta
        </DialogTitle>
        <DialogContent>
          <DialogContentText >
            La tua sessione è scaduta, effettua nuovamente il login
          </DialogContentText>
          <SignInSide
            openWebGis={this.onOpenWebGis}
            checkDataSignIn={this.checkDataSignIn}
            isErrorAuthorized={isErrorAuthorized}
            isLoadingLogin={isLoadingLogin} />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.refreshPage()}>
            Esci
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default SessionExpiredModal